<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/commodity' }">商品列表</el-breadcrumb-item>
                <el-breadcrumb-item>商品编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ good_uuid ? '编辑商品' : '添加商品' }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
                        <el-form-item label="商品名称">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                        <el-form-item label="指定门店">
                            <el-select v-model="form.store_s" multiple filterable placeholder="请选择门店" :loading="false"
                                style="width: 100%">
                                <el-option v-for="(item, key) in store_optionals" :key="key" :label="item.store_name"
                                    :value="item.store_uuid">
                                    <span style="color: #8492a6; font-size: 13px">
                                        【{{ item.company_name }}】
                                    </span>
                                    <span>{{ item.store_name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="运动品类">
                            <div class="category-select">
                                <el-select v-model="motion_tag" filterable placeholder="请选择运动品类" style="width: 100%">
                                </el-select>
                                <div class="category-select-child" @click="isMotionCategory = true"></div>
                            </div>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="(item, index) in motion_category_two"
                                    class="form-tag_colour-tag" effect="dark" closable @close="motionClose(index)">
                                    <!-- ` item.pidName + '-' + item.name` -->
                                    {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果">
                            <div class="category-select">
                                <el-select v-model="motion_effect" filterable placeholder="请选择运动效果" style="width: 100%">
                                </el-select>
                                <div class="category-select-child" @click="isMotioneffect = true"></div>
                            </div>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="(item, index) in target_category_zero"
                                    class="form-tag_colour-tag" effect="dark" closable @close="effectClose(index)">
                                    <!-- ` item.pidName + '-' + item.name` -->
                                    {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="商品视频">
                            <Qnupload v-model="form.video" type="video" />
                            <el-checkbox v-model="form.video_carousel">视频放到轮播图</el-checkbox>
                        </el-form-item>
                        <el-form-item label="商品封面" prop="cover">
                            <Qnupload v-model="form.cover" :isclipper="true" :compress="false" :fixedNumber="[1, 1]" />
                            <span class="form-tip">图片尺寸1024*1024 或 宽:高=1:1，用于商品卡片封面展示。</span>
                        </el-form-item>
                        <el-form-item label="商品照片" prop="photo">
                            <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :compress="false"
                                :fixedNumber="[25, 28]" />
                            <span class="form-tip">图片尺寸 宽:高≈25:28，用于详情页轮播图展示。</span>
                        </el-form-item>
                        <el-form-item label="规格设置">
                            <el-button v-if="!returnSkuState()" type="primary" icon="el-icon-plus"
                                @click="addSku">添加规格</el-button>
                            <template v-else>
                                <div style="display:flex;flex-wrap: wrap;">
                                    <section v-for="(item, index) in sku_data" :key="index"
                                        style="margin-right: 10px;margin-bottom: 10px;">
                                        <input class="specs__input" v-model="item.sku_name"
                                            @blur="onSkuCheck(item, index)" />
                                        <i class="el-icon-delete" style="margin-left: 10px;cursor: pointer;"
                                            @click="deletSku(index)" />
                                        <i v-if="sku_data.length === index + 1" class="el-icon-plus"
                                            style="margin-left: 10px; color: #409eff;cursor: pointer;" @click="addSku" />
                                    </section>
                                </div>
                            </template>

                        </el-form-item>
                        <el-form-item v-if="returnSkuState()" label="规格信息">
                            <section class="specs__table">
                                <div class="specs__table-header">
                                    <section class="specs__table-title">
                                        <p>规格标题</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>价格（元）</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>人数上限</p>
                                    </section>
                                </div>
                                <div class="specs__table-content">
                                    <section v-for="(item, index) in sku_data" :key="index">
                                        <div v-if="item.sku_name" class="specs__table-cell">
                                            <div class="specs__table-title">
                                                <p>{{ item.sku_name }}</p>
                                            </div>
                                            <div class="specs__table-title">
                                                <el-input v-model="item.sku_price" type="number" style="width:60%"
                                                    @blur="mathMin" />
                                            </div>
                                            <div class="specs__table-title">
                                                <el-input v-model="item.sku_qty" type="number" style="width:60%" />
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </el-form-item>
                        <el-form-item label="商品原价￥">
                            <span v-if="returnSkuState()">{{ minPrice }}</span>
                            <el-input-number v-else v-model="form.originally_price" :step="0.01" :min="0" :max="999999"
                                step-strictly></el-input-number>
                        </el-form-item>
                        <el-form-item v-if="!returnSkuState()" label="优惠价格">
                            <el-input-number v-model="form.promotion_price" :step="0.01" :min="0" :max="999999"
                                step-strictly></el-input-number>
                        </el-form-item>
                        <el-form-item label="时长/分钟">
                            <el-input type="number" v-model="form.duration"></el-input>
                        </el-form-item>

                    </el-form>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <h3>购买须知</h3>
                </el-col>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
                        <el-form-item label="有效期至">
                            <el-date-picker style="width: 70%" v-model="form.expiration_time" type="date"
                                value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="使用时间">
                            <el-time-picker is-range v-model="usage_time" range-separator="至" start-placeholder="开始时间"
                                end-placeholder="结束时间" placeholder="选择时间范围" format="HH 点 mm 分" value-format="HH:mm">
                            </el-time-picker>
                        </el-form-item>
                        <el-form-item label="预约信息">
                            <span>提前</span>
                            <el-input-number v-model="form.advance_booking" controls-position="right" :min="0"
                                :max="30"></el-input-number>
                            <span>天预约</span>
                        </el-form-item>
                        <el-form-item label="最多可购买">
                            <el-input-number v-model="form.fraction_limit" controls-position="right" :min="0"
                                :max="30"></el-input-number>
                            <span>份</span>
                        </el-form-item>
                        <el-form-item label="库存数量">
                            <el-input-number v-model="form.stock" controls-position="right" :min="0" :max="1000"
                                :disabled="sku_data.length > 0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="退款">
                            <el-switch v-model="form.is_refund" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item label="退款备注">
                            <el-input v-model="form.refund_remarks" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"
                                maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="简介">
                            <el-input v-model="form.introduce" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"
                                maxlength="100" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="详情">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor_details_rich_text" v-model="form.details_rich_text"
                                idindex="details_rich_text" seewWidth="50%" />
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('product.sportsticket.issave')" @click="save()" size="medium"
                                type="primary">保存
                            </el-button>

                            <el-button size="medium" @click="isreturn">返回
                            </el-button>

                        </el-form-item>
                    </el-form>
                </el-col>

            </el-row>
        </div>

        <MotionCategory v-model="isMotionCategory" :motion_category_two="motion_category_two"
            @selectData="onSelectCategoryData" />
        <Motioneffect v-model="isMotioneffect" :target_category_zero="target_category_zero" @selectData="onMotioneffect" />
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import MotionCategory from '@/components/MotionCategory'
import Motioneffect from '@/components/Motioneffect'
export default {
    components: {
        Wangeditor,
        Qnupload,
        MotionCategory,
        Motioneffect
    },
    data() {
        return {
            select_tag: '',
            tag_list: [],
            loading: true,
            activity_time_type: 1,
            // 规格信息
            sku_data: [],
            // 规格类型 0--> 无规格 1--> 多规格
            sku_type: 0,
            minPrice: 0,
            form: {
                title: '',
                store_uuid_all: [],
                video: '',
                photo: [],
                stock: 0,
                originally_price: 0,
                tag: [],
                promotion_price: 0,
                duration: '',
                expiration_time: '',
                price_indication: 0,
                store_uuid: '',
                store_s: '',
                introduce: '',
                details_rich_text: '',
                advance_booking: '',
                fraction_limit: '',
                is_refund: 1,
                refund_remarks: '',
                cover: ""

            },
            usage_time: ['00:00', '23:59'],
            video_carousel: false,    // 视频是否加入轮播图

            store_uuid: '',
            good_uuid: '',                  // 课表uuid
            store_optionals: [],            // 可选门店信息
            select_store_data: null,        // 选中的门店数据
            isMotionCategory: false,
            motion_tag: '',
            motion_category_two: [],
            isMotioneffect: false,
            target_category_zero: [],
            motion_effect: ''
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
        this.gettag()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore()         // 获取可选门店
            let good_uuid = this.$route.query.good_uuid
            console.log('good_uuid', good_uuid)
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getinfo(good_uuid)
            } else {
                this.loading = false
            }
        },
        // 添加运动品类
        onSelectCategoryData(data) {
            this.motion_category_two = data;
            this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
        },
        // 删除运动品类
        motionClose(index) {
            this.motion_category_two.splice(index, 1);
            this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two.length === 1 ? this.motion_category_two[0].name : ''
        },
        // 添加运动效果
        onMotioneffect(data) {
            this.target_category_zero = data;
            this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
        },
        // 删除运动效果
        effectClose(index) {
            this.target_category_zero.splice(index, 1);
            this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero.length === 1 ? this.target_category_zero[0].name : ''
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'lesson.lesson.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.form.tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.tag.length > 0) {
                for (let v of this.form.tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            this.form.tag.splice(this.form.tag.indexOf(tag), 1);
        },
        // 选择门店
        selectStore(store_uuid) {
            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        //运动票详情
        getinfo(good_uuid) {
            let postdata = {
                api_name: "product.commodity.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid
            }
            // console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                // console.log('json', json)
                if (json.code === 0) {
                    if (!this.Tool.is_empty(json.data.store_uuid_all)) {
                        let store_s = [];
                        for (let v of json.data.store_uuid_all) {
                            // console.log('v', v.store_uuid)
                            store_s.push(v.store_uuid)

                        }
                        json.data.store_s = store_s
                        this.usage_time = json.data.usage_time.split('-')
                    }
                    this.form = json.data
                    this.motion_category_two = json.data.motion_category_two
                    this.target_category_zero = json.data.target_category_zero
                    this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
                    this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
                    this.$refs.wangeditor_details_rich_text.append(json.data.details_rich_text);    // 详情富文本
                    if (json.data.sku_data.length > 0) {
                        this.sku_data = json.data.sku_data;
                        this.mathMin();
                    }
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //保存
        save() {
            const { sku_data } = this;
            let motion_category_two = []
            let target_category_zero = []
            this.motion_category_two.forEach(element => {
                motion_category_two.push(element.id)
            });
            this.target_category_zero.forEach(element => {
                target_category_zero.push(element.id)
            });
            let postdata = {
                api_name: "product.commodity.issave",
                token: this.Tool.get_l_cache('token'),
                motion_category_two,
                target_category_zero
            }
            // 处理所选门店
            this.form.store_uuid_all = []
            for (let v of this.form.store_s) {
                for (let v2 of this.store_optionals) {
                    if (v == v2.store_uuid) {
                        this.form.store_uuid_all.push(v2)
                    }
                }
            }
            if (sku_data.length !== 0) {
                for (let i in sku_data) {
                    if (!sku_data[i].sku_name) {
                        this.$message.error("请输入规格名称");
                        return false;
                    } else {
                        if (!sku_data[i].sku_price) {
                            this.$message.error(`请输入${sku_data[i].sku_name}规格的价格`);
                            return false;
                        }
                        if (!sku_data[i].sku_qty) {
                            this.$message.error(`请输入${sku_data[i].sku_name}规格的人数限制`);
                            return false;
                        }
                    }
                }
            }
            Object.assign(postdata, this.form);
            if (this.returnSkuState()) {
                postdata.sku_type = 1;
                postdata.sku_data = sku_data;
                delete postdata.promotion_price;
                delete postdata.originally_price;
            }

            postdata.motion_category_two = motion_category_two;
            postdata.target_category_zero = target_category_zero;
            if (!this.Tool.is_empty(this.usage_time)) {
                postdata.usage_time = this.usage_time[0] + '-' + this.usage_time[1]
            }
            let reg = RegExp(
                /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
            );
            if (motion_category_two.length === 0) {
                this.$message.error('请选择运动品类');
                return
            }
            if (target_category_zero.length === 0) {
                this.$message.error('请选择运动效果');
                return
            }
            if (postdata["title"].match(reg)) {
                this.$message.error('商品名称不可包含特殊字符');
            } else {
                this.loading = true
                this.Tool.post_data('merchant', postdata, (json) => {
                    this.loading = false
                    if (json.code === 0) {
                        this.$message({
                            message: '操作成功', type: 'success',
                            duration: this.env.message_duration,
                            onClose: () => {
                                this.isreturn();
                            }
                        });
                    } else {
                        this.Tool.errormes(json)
                    }
                })
            }
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/commodity' })
        },
        /****************** 规格设置部分**************** */
        // 添加一条新的规格
        addSku() {
            const { sku_data } = this;
            sku_data.push({
                sku_name: "",
                sku_qty: "",
                sku_price: "",
                sku_state: 1
            });
            this.sku_data = sku_data;
        },
        // 删除一条规格
        deletSku(index) {
            const { sku_data, good_uuid } = this;
            if (good_uuid) {
                if (sku_data[index].sku_number) {
                    sku_data[index].sku_state = 0;
                } else {
                    sku_data.splice(index, 1);
                }
            } else {
                sku_data.splice(index, 1);
            }
            this.sku_data = sku_data;
            if (!this.returnSkuState()) {
                this.minPrice = 0;
            }
            this.mathMin();
        },
        // 检测sku是否有重复
        onSkuCheck(item, index) {
            const { sku_data } = this;
            for (let i in sku_data) {
                if (sku_data[i].sku_name === item.sku_name && parseInt(i) !== index) {
                    this.$message.error("与其他规格内容有重复，请重新输入")
                    sku_data[index].sku_name = "";
                }
            }

            this.sku_data = sku_data;
        },
        // 计算规格中的最小价格
        mathMin() {
            const { sku_data } = this;
            let data = [], priceData = [];
            sku_data.map(item => {
                if (item.sku_state !== 0) {
                    data = data.concat(item);
                }
            });
            data.map(item => {
                if (!this.Tool.is_empty(item.sku_price)) {
                    priceData = priceData.concat(parseFloat(item.sku_price));
                }
            })
            if (priceData.length > 0) {
                const price = Math.min(...priceData);
                this.minPrice = price;
            }
        },
        // 返回是否有设置规格
        returnSkuState() {
            const { sku_data } = this;
            let count = 0;
            if (sku_data.length === 0) {
                return false;
            } else {
                for (let i in sku_data) {
                    if (sku_data[i].sku_state === 0) {
                        count += 1;
                    }
                }
                if (count === sku_data.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.category-select-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 60%;
    cursor: pointer;
}
</style>
